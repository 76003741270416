.artist-profile {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
}

.link {
  text-decoration: none;
}

.artist-pic-name {
  display: flex;
}

.artist-pic {
  margin-top: 10px;
  margin-left: 200px;
}

.artist-pic img {
  object-fit: cover;
  width: 300px;
  height: 300px;
  border: 10px solid #c471ed;
  border-radius: 50%;
}

.artist-name {
  margin-left: 70px;
  margin-top: 100px;
}

.artist-name h3 {
  color: #c471ed;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  font-weight: 400;
  width: 760px;
  text-align: left;
  line-height: 1.5;
}

.artist-name span {
  /* color: #12c2e9; */
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  margin-right: 10px;
  margin-left: -2px;
}

.btns-add-song-post {
  display: flex;
  justify-content: space-between;
  margin-left: 780px;
  margin-top: 100px;
  width: 420px;
}

/* .artist-btns-add {
 
} */

.artist-btns-add h1 {
  border: 2px solid #12c2e9;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 400;
  border-radius: 10px;
  font-size: 30px;
  padding: 15px;
}

.line {
  margin-top: 100px;
  border: 1px solid #a4a7b1;
  margin-left: 200px;
  margin-right: 170px;
}
