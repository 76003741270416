.section-best-our-feature {
  line-height: 1.5;
  height: 700px;
  /* margin-top: -60px; */
}

.best-features-title {
  margin-bottom: 20px;
  font-size: 49px;
  letter-spacing: 3px;
  font-weight: 900;
  text-transform: uppercase;
  /* margin-top: 100px; */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#12c2e9),
    color-stop(#c471ed),
    to(#f64f59)
  );
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.best-features-sub-title {
  font-size: 30px;
  color: #a4a7b1;
  line-height: 1.4;
  font-weight: 400;
}

.grid-4-cols {
  display: grid;
  grid-template-columns: 600px 600px;
  grid-template-rows: 270px 270px;
  column-gap: 50px;
  row-gap: 50px;
  height: 420px;
  margin-top: 270px;
  justify-content: center;
  align-content: center;
}

.card-feature:hover {
  /* transform: translateY(-1rem); */
  /* box-shadow: #12c2e9 0px 0px 10px; */
}

.card-feature {
  display: grid;
  grid-template-columns: 200px 370px;
  grid-template-rows: 290px;
  border: 3px solid #12c2e9;
  /* border-radius: 12px; */
  padding: 6px;
  border-image: linear-gradient(#c471ed, #12c2e9) 1;
  animation: rotate 3.5s ease-in infinite;
}

/* @keyframes rotate {
  50%
    border-image: linear-gradient(360deg, #12c2e9, #c471ed, #f64f59) 1;
  }
} */

.best-feature-icon {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  color: #ebe1e1;
  width: 70px;
  height: 70px;
  padding: 15px;
  border: 1px solid;
  border-radius: 10px;
  /* margin-top: 10px; */
  align-self: center;
  justify-self: center;
}

.best-feature-title {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  color: #12c2e9;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  align-self: start;
  justify-self: start;
  margin-top: 30px;
}

.best-feature-text {
  /* width: 300px; */
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  color: #a4a7b1;
  font-size: 18px;
  font-weight: 300;
  line-height: 2;
  text-align: justify;
  align-self: center;
  justify-self: center;
  margin-top: 10px;
}

.line {
  display: inline-block;
  border-top: 1px solid rgb(115, 110, 110);
  border-radius: 1px;
  width: 1240px;
  margin-top: 20px;
}
