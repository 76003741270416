.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 50px;
}

.login-icon {
  margin-top: -200px;
  width: 50px;
  height: 50px;
  border: 2px solid #c471ed;
  background-color: #c471ed;
  padding: 10px;
  border-radius: 10px;
  color: #ffffff;
}

.login-title h2 {
  font-size: 35px;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-form {
  height: 350px;
  display: flex;
  flex-direction: column;
}

.login input:first-child {
  margin-bottom: 30px;
}

.login input {
  padding-left: 10px;
  font-size: 19px;
  color: aliceblue;
  background: #010b13;
  width: 420px;
  height: 65px;
  border: 0px solid #12c2e9;
  border-radius: 5px;
  box-shadow: #12c2e9 0px 0px 2px;
}

.login input::placeholder {
  color: #a4a7b1;
  margin-left: 10px;
}

.login-btn button {
  cursor: pointer;
  font-size: 25px;
  color: #a4a7b1;
  background-color: #010b13;
  width: 430px;
  height: 55px;
  /* margin-right: -18px; */
  border: 0px solid #12c2e9;
  border-radius: 5px;
  box-shadow: #12c2e9 0px 0px 2px;
}

.forget-password {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 430px;
}

.forget-password h1 {
  font-size: 14px;
  color: #a4a7b1;
}

.errors {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: azure;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: #f64f59 0px 0px 10px;
  width: 420px;
  padding: 15px;
}
.error h3 {
  color: #f64f59;
}
