.event-header {
  background-color: #181b1e65;
  height: 750px;
  margin-top: -50px;
}

.card {
  position: relative;
  width: 650px;
  height: 200px;
  z-index: 1;
}

.left-side-light-1 {
  width: 250px;
  height: 170px;
  bottom: 400px;
  /* left: -320px; */
  box-shadow: #12c2e9bf -200px 690px 500px;
  border-radius: 100%;
  position: absolute;
  z-index: 9999;
}

.right-side-light-1 {
  width: 250px;
  height: 170px;
  bottom: 400px;
  /* top: -195px;
  left: 900px; */
  /* opacity: 0; */
  box-shadow: #c471ed 1600px 690px 500px;
  border-radius: 100%;
  position: absolute;
}

.event-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-img {
  margin-top: -50px;
}

.event-img {
  text-transform: uppercase;
  font-size: 350px;
  font-weight: 500;
  -webkit-text-stroke: 0.5px #12c2e9;
  color: #111215;
  opacity: 0.5;
  /* outline: 4px rgba(2, 22, 48, 1) solid; */
}

.event-title {
  margin-top: -100px;
}
.event-title h3 {
  font-size: 60px;
  letter-spacing: 3px;
  text-transform: uppercase;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.event-sub-title {
}
.event-sub-title h1 {
  font-size: 30px;
  color: #a4a7b1;
  line-height: 1.4;
  font-weight: 300;
}
