.konto-user {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 400px;
}

.side-menu {
  /* background-color: #12c2e9;
  opacity: 0.5; */
  background-color: rgb(80, 59, 87);
  /* opacity: 0.5; */
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  border: 1px solid #56079c;
  border-radius: 10px;
  height: 500px;
  width: 300px;
  box-shadow: rgb(80, 59, 87) 0px 0px 10px;
  align-items: flex-start;
}

.konto {
  cursor: pointer;
  box-shadow: #e88080 0px 0px 5px;
  border: 0px solid;
  border-radius: 5px;
  margin-top: 80px;
  margin-left: 15px;
  padding-left: 20px;
  padding-right: 20px;
  width: 230px;
}

.konto h1 {
  color: #8fb0e9;
  font-size: 30px;
  font-weight: 400;
}
