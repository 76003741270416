* {
  padding: 0;
  margin: 0;
}

body {
  text-align: center;
  background: #010b13;
  /* background: #111215; */
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

p {
  color: #a4a7b1;
  font-size: 16px;
  font-weight: 300;
  line-height: 2;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
