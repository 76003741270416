.grid-2 {
  display: grid;
  grid-template-columns: 480px 550px;
  grid-template-rows: 0px 100px 250px;
  column-gap: 100px;
  row-gap: 30px;
  height: 500px;
  justify-content: center;
  align-content: center;
}

/* div {
    display: grid;
  } */

.event-image {
  object-fit: cover;
  width: 100%;
  height: 380px;
  border-radius: 10px;
  grid-column: 1/2;
  grid-row: 2 / 4;
  justify-self: center;
  align-self: end;
  filter: brightness(90%);
}

.event-image:hover {
  box-shadow: #12c2e9 0px 0px 15px;
}

.event-title {
  grid-column: 2/3;
  grid-row: 2 / 3;
  align-self: start;
}
.event-title h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
  color: #f8f8f8;
  text-align: justify;
  font-size: 30px;
  font-weight: 500;
}

.event-text {
  grid-column: 2/3;
  grid-row: 3 / 4;
}

.event-text p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  text-align: justify;
}

.event-button {
  cursor: pointer;
  grid-column: 2/3;
  grid-row: 3 / 4;
  align-self: end;
  justify-self: start;
  width: 125px;
  height: 40px;
  color: #f8f8f8;
  font-size: 18px;
  font-weight: 300;
  border: 1px solid;
  background: #111215;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  padding-top: 12px;
}

.event-button:hover {
  border: 1px solid #12c2e9;
  color: #12c2e9;
}

.dates {
  display: inline-block;
  position: relative;
  display: inline-block;
  grid-column: 2/3;
  grid-row: 3 / 4;
  justify-self: start;
  align-self: center;
  margin-top: 40px;
}

.icon-date {
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 300px;
  color: #f64f59;
  width: 25px;
  height: 25px;
}

.date {
  color: azure;
  margin-left: 40px;
  font-size: 20px;
  font-weight: 500;
}

.line {
  display: inline-block;
  border-top: 1px solid rgb(115, 110, 110);
  border-radius: 1px;
  width: 1240px;
  margin-top: 10px;
}
