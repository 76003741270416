section {
  position: relative;
}

.grid-2-cols {
  display: grid;
  grid-template-columns: 480px 550px;
  grid-template-rows: 25px 80px 200px 50px;
  column-gap: 130px;
  row-gap: 30px;
  height: 600px;
  /* border: 5px solid; */
  /* width: 1500px; */
  margin-left: 50px;
  /* margin-top: 20px; */
  margin-bottom: 150px;
  justify-content: center;
  align-content: center;
}

.feedback-username {
  position: relative;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  justify-self: start;
}

.name {
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  margin-top: -30px;
}

.icon {
  width: 150px;
  height: 150px;
  position: absolute;
  /* transform: rotate(5deg); */
  top: -60px;
  left: -60px;
  opacity: 0.3;
}

.image {
  border: 10px solid transparent;
  /* border-color: #f64f59; */
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 420px;
  height: 420px;
  grid-column: 2/3;
  grid-row: 1 / 3;
  margin-top: 25px;
  /* box-shadow: 0px 5px 15px #c471ed, 0px 5px 15px #f64f59; */
  border-image: linear-gradient(#12c2e9, #c471ed, #f64f59) 1;
  filter: brightness(90%);
  animation: rotate 4s ease-in infinite;
}

@keyframes rotate {
  50% {
    border-image: linear-gradient(360deg, #12c2e9, #c471ed, #f64f59) 1;
  }
}

.image:hover {
  box-shadow: #12c2e9 0px 0px 15px;
}

.title h3 {
  font-size: 36px;
  letter-spacing: 2px;
  text-transform: uppercase;
  /* margin-top: 100px; */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#12c2e9),
    color-stop(#c471ed),
    to(#f64f59)
  );
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-title h1 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 18px;
  color: #a4a7b1;
  line-height: 1.4;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.feedback-text {
  grid-column: 1/2;
  grid-row: 3 / 4;
  font-size: 19px;
  font-weight: 500;
  line-height: 2;
  text-align: justify;
}

.arrow-right {
  cursor: pointer;
  width: 60px;
  height: 60px;
  border: 5px solid #12c2e9;
  border-radius: 50px;
  padding: 15px;
  top: 400px;
  right: 50px;
  color: aliceblue;
  position: absolute;
  opacity: 0.5;
}

.arrow-right:hover {
  box-shadow: #12c2e9 0px 0px 15px;
}

.arrow-left {
  cursor: pointer;
  width: 60px;
  height: 60px;
  border: 5px solid #12c2e9;
  border-radius: 50px;
  padding: 15px;
  top: 400px;
  left: 50px;
  color: aliceblue;
  position: absolute;
  opacity: 0.5;
}

.arrow-left:hover {
  box-shadow: #12c2e9 0px 0px 15px;
}

/*********************** Media query **************************/

@media (max-width: 1200px) {
  .grid-2-cols {
    grid-template-columns: 480px 450px;
    grid-template-rows: 25px 80px 200px 50px;
    column-gap: 50px;
    row-gap: 30px;
  }

  .image {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    grid-column: 2/3;
    grid-row: 1 / 3;
    align-self: start;
    justify-self: end;
    object-fit: cover;
    filter: brightness(90%);
  }
}

@media (max-width: 1080px) {
  .grid-2-cols {
    margin-top: 180px;
    grid-template-columns: 550px;
    grid-template-rows: 490px 80px 250px 50px;
    /* column-gap: 130px; */
    row-gap: 10px;
    justify-content: center;
    align-content: center;
  }

  .image {
    width: 100%;
    height: 450px;
    border-radius: 10px;
    grid-column: 1/2;
    grid-row: 1 / 2;
    object-fit: cover;
    filter: brightness(90%);
  }

  .feedback-username {
    position: relative;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: start;
  }

  .name {
    font-size: 40px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 10px;
  }

  .icon {
    width: 100px;
    height: 100px;
    position: absolute;
    /* transform: rotate(5deg); */
    top: -10px;
    left: -60px;
    opacity: 0.3;
  }
}
