.update-post {
  height: 600px;
}

.add-music-header {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 50px;
}

.add-music-icon {
  width: 50px;
  height: 50px;
  border: 2px solid #c471ed;
  background-color: #c471ed;
  padding: 10px;
  border-radius: 10px;
  color: #ffffff;
}

.add-music-title h2 {
  font-size: 35px;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.song-form-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

label {
  font-size: 21px;
  color: aliceblue;
  margin-bottom: 20px;
}

.label-form-input {
  font-size: 21px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.input-form input {
  padding: 10px;
  font-size: 19px;
  color: aliceblue;
  background: #010b13;
  width: 420px;
  height: 65px;
  border: 0px solid #12c2e9;
  border-radius: 5px;
  box-shadow: #12c2e9 0px 0px 2px;
}
.input-form-file input {
  padding: 10px;
  font-size: 19px;
  color: aliceblue;
  background: #010b13;
  width: 420px;
  height: 65px;
  border: 0px solid #12c2e9;
  border-radius: 5px;
  box-shadow: #12c2e9 0px 0px 2px;
}

.actions button {
  cursor: pointer;
  font-size: 25px;
  color: #a4a7b1;
  background-color: #010b13;
  width: 430px;
  height: 55px;
  margin-top: 30px;
  border: 0px solid #12c2e9;
  border-radius: 5px;
  box-shadow: #12c2e9 0px 0px 2px;
}

.errors {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: azure;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: #f64f59 0px 0px 10px;
  width: 420px;
  padding: 15px;
}
.error h3 {
  color: #f64f59;
}

.loader-container {
  height: 500px;
  margin-top: 260px;
}
