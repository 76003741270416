.title-footer {
  font-size: 35px;
  color: azure;
  margin-right: 380px;
  text-align: justify;
  width: 500px;
}

.recent-event {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 500px;
  grid-template-rows: 70px 100px 100px;
  row-gap: 20px;
  /* justify-item: start; */
  /* height: 360px; */
}

.wraper {
  display: grid;
}

.recent-event-image {
  grid-row: 1/2;
  grid-column: 1/2;
  justify-self: start;
}

.recent-event-image img {
  object-fit: cover;
  width: 150px;
  height: 100px;
  margin-top: 10px;
}

.recent-event-info {
  grid-column: 1/2;
  grid-row: 1/2;
  width: 470px;
}

.items {
  font-size: 18px;
  color: azure;
  font-weight: 400;
  /* margin-left: 70px; */
}

.item {
  text-align: left;
  color: #a4a7b1;
  font-size: 15px;
  font-weight: 300;
  margin-left: 165px;
}

/*********************** Media query **************************/

@media (max-width: 1360px) {
  .recent-event {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
  }
}

/* @media (max-width: 1115px) {
  .recent-event {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
  }
} */
