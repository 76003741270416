.section-who-we-are {
  /* margin-top: -200px; */
  height: 500px;
}

.who-we-are-card {
  margin-top: 500px;
  display: flex;
  /* justify-content: space-between; */
  gap: 180px;
  margin-left: 100px;
}

.who-we-are {
  width: 300px;
  margin-left: 150px;
  font-size: 30px;
  font-weight: 600;
  color: #f8f9fa;
}

.who-we-are-text {
  width: 900px;
  font-size: 21px;
  margin-right: 220px;
  font-weight: 400;
  text-align: justify;
}
