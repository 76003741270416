.about-header {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #181b1e65;
  height: 700px;
  margin-top: -50px;
}

.card-about {
  width: 50px;
  height: 100px;

  /* position: fixed; */
  z-index: 1;
}

.left-side-light-2 {
  width: 250px;
  height: 190px;
  top: 570px;
  left: -320px;
  box-shadow: #12c2e9bf 200px -50px 300px;
  border-radius: 100%;
  position: absolute;
}

.right-side-light-1 {
  width: 250px;
  height: 190px;
  top: -265px;
  right: 30px;
  opacity: 0.5;
  box-shadow: #c471ed 100px 380px 500px;
  border-radius: 100%;
  position: absolute;
}

.about-header-img {
  position: absolute;
  top: 500px;
  z-index: 2;
}
.about-header-img img {
  height: 550px;
}

.about-header-background {
  width: 1100px;
  height: 500px;
}

.abouts {
  position: relative;
  width: 1000px;
  font-size: 40px;
  margin-bottom: 200px;
  letter-spacing: 3px;
  text-align: center;
  text-transform: uppercase;
  /* margin-top: 100px; */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#12c2e9),
    color-stop(#c471ed),
    to(#f64f59)
  );
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-header-title {
}

/* .about-sub-title {
  position: absolute;
  top: 350px;
  left: 340px;
  font-size: 30px;
  color: #a4a7b1;
  line-height: 1.4;
  font-weight: 400;
} */
