.image {
  position: relative;
}

.main-image {
  margin-top: -50px;
}

.main-image img {
  /* object-fit: initial; */
  width: 100%;
  height: 700px;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title h1 {
  color: azure;
  font-weight: 500;
  background-color: rgb(17, 16, 17);
  opacity: 0.5;
  font-size: 50px;
  border-radius: 10px;
  padding: 10px;
}
