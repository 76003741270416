section {
  /* line-height: 1.5;
  font-weight: 400; */
}

.grid-3-cols {
  display: grid;
  grid-template-columns: 380px 380px 380px;
  grid-template-rows: 350px 50px;
  column-gap: 50px;
  height: 420px;
  margin-top: 50px;
  justify-content: center;
  align-content: center;
}

.section:hover {
  transform: translateY(-1rem);
}

.section {
  border: 3px solid;
  padding-left: 20px;
  padding-right: 20px;
  border-image: linear-gradient(#12c2e9, #c471ed, #f64f59) 1;
  animation: rotate 3.5s ease-in infinite;
}

@keyframes rotate {
  50% {
    border-image: linear-gradient(360deg, #12c2e9, #c471ed, #f64f59) 1;
  }
}

.icon {
  color: #ebe1e1;
  width: 70px;
  height: 70px;
  padding: 20px;
  border-radius: 70px;
  margin-top: 10px;

  align-self: center;
  justify-self: center;
}

.features-title {
  color: #12c2e9;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  align-self: center;
  justify-self: center;
}

.features-text {
  color: #a4a7b1;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  text-align: justify;
  margin-top: 0px;
  padding: 0px 0px;
}

.line {
  border-top: 1px solid rgb(115, 110, 110);
  border-radius: 1px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
}

/*********************** Media query **************************/

@media (max-width: 1260px) {
  .grid-3-cols {
    display: grid;
    grid-template-columns: 1000px;
    grid-template-rows: 350px;
    row-gap: 50px;
    height: 420px;
    margin-top: 50px;
    justify-content: center;
    align-content: center;
    margin-top: 370px;
    margin-bottom: 350px;
  }

  .features-text {
    font-size: 18px;
    font-weight: 400;
    padding: 20px;
  }

  .features-title {
    font-size: 24px;
  }
}

@media (max-width: 1030px) {
  .grid-3-cols {
    display: grid;
    grid-template-columns: 600px;
    grid-template-rows: 350px;
    row-gap: 50px;
    height: 420px;
    margin-top: 50px;
    justify-content: center;
    align-content: center;
    margin-top: 370px;
    margin-bottom: 350px;
  }

  .features-text {
    font-size: 18px;
    font-weight: 400;
    padding: 20px;
  }

  .features-title {
    font-size: 24px;
  }
}

@media (max-width: 1030px) {
  .grid-3-cols {
    display: grid;
    grid-template-columns: 450px;
    grid-template-rows: 350px;
    row-gap: 50px;
    height: 420px;
    margin-top: 50px;
    justify-content: center;
    align-content: center;
    margin-top: 400px;
    margin-bottom: 380px;
  }

  .features-text {
    font-size: 14px;
    font-weight: 400;
    padding: 20px;
  }

  .features-title {
    font-size: 20px;
  }
}
