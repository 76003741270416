section {
  margin-top: 100px;
}

.link {
  text-decoration: none;
}

.grid-3-cols {
  display: grid;
  grid-template-columns: 400px 400px 400px;
  column-gap: 50px;
  height: 600px;
  justify-content: center;
  align-content: center;
  margin-top: 150px;
}

.card {
  height: 550px;
  border: 1px solid #707070;
  border-radius: 15px;
  margin-bottom: 50px;
  box-shadow: #c471ed 0px 0px 10px;
  /* margin-top: 200px; */
}

.card:hover {
  transform: translateY(-1rem);
  box-shadow: #f64f59 0px 5px 15px;
  border-radius: 15px;
}

.title {
  font-size: 36px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-title h1 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 18px;
  color: #a4a7b1;
  line-height: 1.4;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.post-image {
  width: 390px;
  /* height: 300px; */
  height: 320px;
}

.post-image:hover {
  border-radius: 10px;
}

.post-image img {
  object-fit: cover;
  width: 100%;
  height: 320px;
  border-radius: 10px;
  padding: 4px;
}

.post-image img:hover {
  /* border-radius: 30px; */
}

.event-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 20px;
  margin-left: 10px;
  width: 425px;
}
.event-title h1 {
  color: #12c2e9;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: left;
}

.event-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.event-description p {
  color: #a4a7b1;
  font-size: 14px;
  line-height: 2;
  text-align: justify;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Roboto", sans-serif;
}

.event-date {
  display: flex;
  align-items: center;
  padding: 20px;
}

.icon {
  color: #f64f59;
  width: 35px;
  height: 35px;
  margin-left: -10px;
}

span {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #a4a7b1;
  margin-left: 20px;
}

.line {
  border-top: 1px solid rgb(115, 110, 110);
  border-radius: 1px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
}

/*********************** Media query **************************/

@media (min-width: 855px) and (max-width: 1380px) {
  .grid-3-cols {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 1800px;
    margin-top: 150px;
  }

  .card {
    height: 560px;
    width: 800px;
    border: 1px solid #707070;
    border-radius: 15px;
    margin-bottom: 50px;
    box-shadow: #c471ed 0px 0px 10px;
    /* margin-top: 200px; */
  }

  .post-image {
    width: 790px;
    /* height: 300px; */
    height: 320px;
  }

  .post-image:hover {
    border-radius: 10px;
  }

  .post-image img {
    object-fit: cover;
    width: 100%;
    height: 320px;
    border-radius: 10px;
    padding: 4px;
  }
  .event-description p {
    font-size: 18px;
  }
  .event-title h1 {
    font-size: 25px;
  }
}

@media (max-width: 855px) {
  .grid-3-cols {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 1800px;
    margin-top: 150px;
  }

  .card {
    width: 400px;
  }
}
