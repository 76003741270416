.footers {
  background-color: #181b1e65;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 450px 450px 450px;
  grid-template-rows: 450px;
  justify-content: center;
  z-index: 9999;
}

.contact {
  z-index: 9999;
  margin-top: 50px;
  display: grid;
  grid-template-columns: 300px;
  grid-template-rows: 70px 70px 70px 70px;
  row-gap: 20px;
  justify-content: flex-end;
  margin-right: 100px;
}

.title-footer {
  font-size: 35px;
  color: azure;
  margin-right: 380px;
  text-align: justify;
  width: 500px;
}

.contact-item {
  position: relative;
  /* margin-top: 100px; */
  /* color: #a4a7b1; */
}

.items {
  text-align: justify;
  font-size: 18px;
  color: azure;
  font-weight: 400;
  margin-left: 60px;
}

.icon {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 25px;
  height: 25px;
  color: azure;
}

.item {
  text-align: justify;
  color: #a4a7b1;
  margin-left: 60px;
  font-size: 15px;
  font-weight: 300;
}

/*********************** Media query **************************/

@media (max-width: 1360px) {
  .footers {
    background-color: #181b1e65;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  /* .contact {
    margin-right: 20px;
  } */

  .contact {
    display: flex;
    flex-direction: column;
  }

  .title-footer {
    font-size: 55px;
  }

  .contact-item {
    position: relative;
  }

  .items {
    text-align: justify;
    font-size: 28px;
    color: azure;
    font-weight: 400;
    margin-left: 100px;
  }

  .icon {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 45px;
    height: 45px;
    color: azure;
  }

  .item {
    text-align: justify;
    color: #a4a7b1;
    margin-left: 100px;
    font-size: 15px;
    font-weight: 300;
  }
}
