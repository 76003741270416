.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  /* gap: 300px; */
  background-color: #010b13;
  z-index: 999;
  /* box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.03); */
}

.hamburger-menu {
  display: none;
}

.main-nav {
  position: relative;
}

.logo {
  margin-top: 2px;
  border: 1px solid #ecf6f8;
  border-radius: 5px;
  padding: 5px;
  width: 80px;
}
.logo h2 {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#12c2e9),
    color-stop(#c471ed),
    to(#f64f59)
  );
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: -220px;
  gap: 4.8rem;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #a4a7b1;
  font-weight: 400;
  font-size: 25px;
  margin-top: 10px;
  transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active,
.main-nav-link.active {
  color: #12c2e9;
  /* box-shadow: #12c2e9 0px 0px 5px; */
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #fff;
  background-color: #12c2e9;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: #12c2e9;
}

.btn {
  border-radius: 30px;
}

.btn-login {
  text-decoration: none;
  padding: 10px;
  margin-left: 20px;
  cursor: pointer;
  width: 125px;
  height: 47px;
  font-size: 21px;
  color: #f8f8f8;
  border: 2px solid;
  background: #111215;
  border-image: linear-gradient(#c471ed, #12c2e9, #f64f59) 1;
  /* animation: rotate 3.5s ease-in infinite; */
}

@keyframes rotate {
  50% {
    border-image: linear-gradient(360deg, #12c2e9, #c471ed, #f64f59) 1;
  }
}

.btn-login:hover {
  box-shadow: #12c2e9 0px 0px 5px;
}

.btn-signup {
  text-decoration: none;
  padding: 10px;
  font-size: 21px;
  cursor: pointer;
  width: 145px;
  height: 47px;
  color: #f8f8f8;
  border: 2px solid;
  padding-left: 5px;
  padding-right: 5px;
  background: #111215;
  border-image: linear-gradient(#c471ed, #12c2e9, #f64f59) 1;
  animation: rotate 3.5s ease-in infinite;
}

@keyframes rotate {
  50% {
    border-image: linear-gradient(360deg, #12c2e9, #c471ed, #f64f59) 1;
  }
}

.btn-signup:hover {
  box-shadow: #12c2e9 0px 0px 5px;
}

.profil-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.pofil-pic-btn {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 130px;
}

.pofil-pic-btn img {
  object-fit: fill;
  height: 50px;
  width: 50px;
  border: 4px solid #12c2e9;
  border-radius: 50%;
}

.user-profil-list {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: absolute;
  top: 82px;
  right: 50px;
  background-color: #a4a7b1;
  width: 200px;
  height: 200px;
  border-radius: 20px;
}

.user-profil-list-link {
  cursor: pointer;
  list-style: none;
  font-size: 21px;
  text-align: left;
  padding: 5px;
}

.user-profil-list-link:first-child {
  margin-top: 30px;
}

/*********************** Media query **************************/

@media (max-width: 1365px) {
  .main-nav {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }
  .hamburger-menu-logo {
    position: relative;
    display: flex;
  }
  .hamburger-menu {
    width: 50px;
    height: 50px;
    color: #ffffff;
  }
  .hamburger-nav {
    display: flex;
    position: absolute;
    top: 80px;
    left: 5px;
    width: 250px;
    height: 400px;
    background-color: #010b13;
    opacity: 0.8;
  }

  .hamburger-nav-link {
    list-style: none;
    display: flex;
    align-items: center;
    margin-right: 100px;
    gap: 4.8rem;
    margin-left: 20px;
    text-decoration: none;
    color: #a4a7b1;
    font-weight: 400;
    font-size: 25px;
    margin-top: 10px;
    padding: 10px;
  }

  /* .hamburger-nav-link:link,
  .hamburger-nav-link:visited {
    display: inline-block;
    margin-left: 20px;
    text-decoration: none;
    color: #a4a7b1;
    font-weight: 400;
    font-size: 25px;
    margin-top: 10px;
    transition: all 0.3s;
  } */
}
