.loader-container {
  height: 500px;
  margin-top: 260px;
}

.about-artist {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-me {
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.about-me h1 {
  color: #c471ed;
  font-size: 35px;
  margin-bottom: 50px;
}

.about-me p {
  font-size: 21px;
  text-align: justify;
}

.my-project {
  margin-top: 100px;
  margin-bottom: 150px;
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.my-project h1 {
  color: #c471ed;
  font-size: 35px;
  margin-bottom: 50px;
}

.my-project p {
  font-size: 21px;
  text-align: justify;
}
