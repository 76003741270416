.section-wraper {
  display: flex;
  flex-direction: column;
}

.event-details {
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* margin-left: 220px; */
  gap: 100px;
  margin-bottom: 150px;
}

.event-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  width: 350px;
}

.event-info-detail h2 {
  color: #12c2e9;
  font-size: 27px;
  font-weight: 500;
}

.date {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.date h2 {
  color: #a4a7b1;
  margin-left: 50px;
  font-size: 23px;
  font-weight: 400;
}

.date h1 {
  color: #a4a7b1;
  margin-left: 50px;
  font-size: 17px;
  font-weight: 300;
  padding-top: 10px;
}

.date-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 2px;
  left: 10px;
  color: #12c2e9;
}

.address {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.address h2 {
  color: #a4a7b1;
  margin-left: 50px;
  font-size: 23px;
  font-weight: 400;
}

.address h1 {
  color: #a4a7b1;
  margin-left: 50px;
  font-size: 17px;
  font-weight: 300;
  padding-top: 10px;
}

.address-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 2px;
  left: 10px;
  color: #12c2e9;
}

.section-event-header {
  margin-top: 200px;
}

.event-header-page {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.event-page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.event-page-title h3 {
  margin-top: 50px;
  font-size: 42px;
  letter-spacing: 2px;
  font-weight: 500;
  text-transform: uppercase;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.event-image-page img {
  width: 1300px;
  height: 600px;
  object-fit: cover;
}

.event-info {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 800px;
}

.event-info-title h2 {
  font-size: 27px;
  font-weight: 500;
  text-align: justify;
  color: #12c2e9;
}

.event-info-sub-title h1 {
  color: #a4a7b1;
  font-size: 21px;
  text-align: justify;
  font-weight: 500;
}

.event-text-teil1 {
  color: #a4a7b1;
  text-align: justify;
  font-size: 18px;
  font-weight: 400;
}

.loader-container {
  height: 500px;
  margin-top: 260px;
}
