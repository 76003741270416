.user-contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-contents-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  width: 600px;
  border: 3px solid #a4a7b1;
  border-radius: 10px;
  padding: 10px;
}

.btn-contents-songs h1 {
  cursor: pointer;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 3px solid #12c2e9;
  border-radius: 10px;
  width: 150px;
  padding: 10px;
  font-size: 30px;
  font-weight: 400;
}

.btn-contents-posts h1 {
  cursor: pointer;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 3px solid #12c2e9;
  border-radius: 10px;
  width: 150px;
  padding: 10px;
  font-size: 30px;
  font-weight: 400;
}

.user-contents-bar {
  display: flex;
}

.contents-songs {
  margin-top: 50px;
}

.contents-songs {
  font-size: 40px;
  color: #f64f59;
}

.contents-posts {
  font-size: 40px;
  color: #c471ed;
}
