.signup-header {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 50px; */
  margin-bottom: 50px;
}

.signup-icon {
  width: 50px;
  height: 50px;
  border: 2px solid #c471ed;
  background-color: #c471ed;
  padding: 10px;
  border-radius: 10px;
  color: #ffffff;
}

.signup-title h2 {
  font-size: 35px;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.signup {
  margin-bottom: 20px;
}

.signup input {
  margin-left: 50px;
  padding-left: 10px;
  font-size: 19px;
  color: aliceblue;
  background: #010b13;
  width: 420px;
  height: 65px;
  border: 0.5px solid #12c2e9;
  border-radius: 5px;
  box-shadow: #12c2e9 0px 0px 2px;
}
.signup:first-child {
  margin-right: 5px;
}
.signup:nth-child(2) {
  margin-right: 20px;
}
.signup:nth-child(3) {
  margin-right: -25px;
}
.signup:nth-child(4) {
  margin-right: 5px;
}
.signup:nth-child(5) {
  margin-right: 15px;
}
.signup:nth-child(6) {
  margin-right: 30px;
}

.signup input::placeholder {
  padding: 10px;
  color: #a4a7b1;
  margin-left: 10px;
}

.signup textarea {
  padding: 10px;
  background: #010b13;
  color: #a4a7b1;
  border: 0.5px solid #12c2e9;
  border-radius: 10px;
  margin-left: 60px;
}

.signup label {
  top: 10px;
  font-size: 21px;
  color: #12c2e9;
}

/* label:after {
  content: "";
} */

.submit-btn button {
  cursor: pointer;
  font-size: 25px;
  color: #a4a7b1;
  background-color: #010b13;
  width: 430px;
  height: 55px;
  margin-right: -130px;
  border: 0px solid #12c2e9;
  border-radius: 5px;
  box-shadow: #12c2e9 0px 0px 2px;
}

.forget-password {
  display: flex;
  justify-content: space-between;
  width: 430px;
  margin-top: 20px;
  margin-left: 700px;
}

.forget-password h1 {
  font-size: 14px;
  color: #a4a7b1;
}

.errors {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: azure;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: #f64f59 0px 0px 10px;
  width: 420px;
  padding: 15px;
}
.error h3 {
  color: #f64f59;
}
