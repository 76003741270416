.grid-2-cols {
  display: grid;
  grid-template-columns: 480px 550px;
  grid-template-rows: 25px 80px 200px 50px;
  column-gap: 130px;
  row-gap: 30px;
  height: 600px;
  margin-bottom: -50px;
  justify-content: center;
  align-content: center;
}

.image {
  width: 100%;
  height: 450px;
  border-radius: 10px;
  grid-column: 2/3;
  grid-row: 1 / 3;
  object-fit: cover;
  filter: brightness(90%);
}

.image:hover {
  box-shadow: #12c2e9 0px 0px 10px;
}

.features-title h2 {
  display: inline-block;
  color: #a4a7b1;
  margin-right: 460px;
  text-transform: uppercase;
}

.features-sub-title {
  grid-column: 1/2;
  grid-row: 2 / 3;
  align-self: start;
  justify-self: start;
  /* margin-top: -5px; */
  margin-left: -4px;
}
.features-sub-title h3 {
  width: 297.8px;
  font-size: 36px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-text {
  grid-column: 1/2;
  grid-row: 3 / 4;
  width: 500px;
  text-align: justify;
  margin-top: -50px;
}
.features-text p {
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
}

.button {
  cursor: pointer;
  grid-column: 1/2;
  grid-row: 4 / 5;
  align-self: start;
  justify-self: start;
  width: 125px;
  height: 37px;
  color: #f8f8f8;
  border: 1px solid;
  background: #111215;
  border-radius: 5px;
  text-decoration: none;
  padding-top: 10px;
}

.button:hover {
  border: 1px solid #12c2e9;
  color: #12c2e9;
}

.line {
  border-top: 1px solid rgb(115, 110, 110);
  border-radius: 1px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 100px;
}

/*********************** Media query **************************/

@media (max-width: 1200px) {
  .grid-2-cols {
    grid-template-columns: 480px 450px;
    grid-template-rows: 25px 80px 200px 50px;
    column-gap: 50px;
    row-gap: 30px;
  }

  .image {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    grid-column: 2/3;
    grid-row: 1 / 3;
    align-self: start;
    justify-self: end;
    object-fit: cover;
    filter: brightness(90%);
  }

  .line {
    border-top: 1px solid rgb(115, 110, 110);
    border-radius: 1px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 100px;
  }
}

@media (max-width: 1030px) {
  .grid-2-cols {
    grid-template-columns: 550px;
    grid-template-rows: 250px 80px 250px 50px;
    /* column-gap: 130px; */
    row-gap: 10px;
    height: 600px;
    /* margin-bottom: -50px; */
    justify-content: center;
    align-content: center;
  }

  .image {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    grid-column: 1/2;
    grid-row: 1 / 2;
    object-fit: cover;
    filter: brightness(90%);
  }

  .features-title {
    grid-column: 1/2;
    grid-row: 2 / 2;
    display: inline-block;
    align-self: start;
    justify-self: start;
  }
  .features-title h2 {
    color: #a4a7b1;
    margin-right: 460px;
    text-transform: uppercase;
  }

  .features-sub-title {
    grid-column: 1/2;
    grid-row: 2 / 3;
    align-self: end;
    justify-self: start;
    margin-left: -30px;
  }
  .features-sub-title h3 {
    width: 297.8px;
    font-size: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .features-text {
    grid-column: 1/2;
    grid-row: 3 / 4;
    width: 500px;
    text-align: justify;
    margin-top: 0px;
  }
  .features-text p {
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
    text-align: justify;
  }

  .button {
    cursor: pointer;
    grid-column: 1/2;
    grid-row: 4 / 5;
    align-self: start;
    justify-self: start;
    width: 125px;
    height: 37px;
    color: #f8f8f8;
    border: 1px solid;
    background: #111215;
    border-radius: 5px;
    text-decoration: none;
    padding-top: 10px;
  }

  .line {
    border-top: 1px solid rgb(115, 110, 110);
    border-radius: 1px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 150px;
  }
}
