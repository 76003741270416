.section-songs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 00px; */
}

.song-audio-player {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}

.title {
  width: 200px;
  height: 50px;
  border-radius: 10px;
  /* background: #060606; */
}

.title h3 {
  border: 2px solid #f64f59;
  border-radius: 10px;
  width: 200px;
  font-size: 36px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 40px;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.setting {
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 370px;
  width: 35px;
  height: 35px;
  color: #c471ed;
  /* margin-left: 290px; */
}

.setting-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100px;
  height: 100px;
  background-color: #a4a7b1;
  position: absolute;
  top: 60px;
  right: 400px;
  border-radius: 10px;
}

.setting-list-link {
  cursor: pointer;
  font-size: 20px;
  list-style: none;
  margin-left: 23px;
  color: #060606;
}

.song-link {
  text-decoration: none;
}

.audio-player {
  display: grid;
  grid-template-columns: 500px 350px;
  grid-template-rows: 70px 110px 50px 70px;
  justify-content: center;
  width: 851px;
  border-radius: 20px;
  background-color: #181b1e65;
  box-shadow: #12c2e9bf 0px 0px 10px;
}

.song-pic {
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  margin-top: 1px;
}

.song-pic img {
  width: 350px;
  height: 300px;
  border-radius: 0 20px 20px 0px;
}

.user-pic {
  grid-column: 1/ 2;
  grid-row: 2 / 3;
  justify-self: start;
  align-self: end;
  padding-left: 75px;
}

.user-pic img {
  width: 35px;
  height: 35px;
  border: 2px solid #12c2e9bf;
  border-radius: 50%;
}

.song-username {
  grid-column: 1/ 2;
  grid-row: 2 / 3;
  justify-self: start;
  align-self: end;
  margin-left: 130px;
  padding-bottom: 10px;
}

.song-username h1 {
  text-transform: uppercase;
  font-size: 20px;
  color: #a4a7b1;
  font-weight: 300;
}

.song-username span {
  text-transform: uppercase;
  font-size: 20px;
  color: #a4a7b1;
  font-weight: 300;
  margin-left: 5px;
}

.music-art {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  margin-left: 80px;
  margin-top: 20px;
}

.music-art h1 {
  font-size: 14px;
  font-weight: 500;
  color: #a4a7b1;
  /* border: 1px solid; */
  background-color: #f64f59;
  border-radius: 20px;
  width: 90px;
}

.song-title {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  justify-self: start;
  align-self: end;
  margin-left: 80px;
}

.song-title h2 {
  font-size: 30px;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.audio {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  margin-top: 5px;
}

.play {
  margin-left: 20px;
  margin-right: 20px;
  height: 45px;
  width: 45px;
  color: #12c2e9bf;
}

.backward {
  height: 45px;
  width: 45px;
  color: #12c2e9bf;
}

.forward {
  height: 45px;
  width: 45px;
  color: #12c2e9bf;
}
