section {
  margin-top: 100px;
}
.link {
  text-decoration: none;
}

.features-title h3 {
  font-size: 36px;
  letter-spacing: 2px;
  text-transform: uppercase;

  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#12c2e9),
    color-stop(#c471ed),
    to(#f64f59)
  );
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-title h1 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 18px;
  color: #a4a7b1;
  line-height: 1.4;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.card {
  display: grid;
  grid-template-columns: 490px 490px 490px;
  height: 400px;
  justify-content: center;
  align-content: center;
  column-gap: 35px;
  /* height: 70px; */
}

.wrap {
  position: relative;
  display: flex;
  box-shadow: #c471ed 0px 0px 10px;
  border-radius: 10px;
}
.text {
  width: 290px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.img {
  width: 200px;
  height: 200px;
}

.img img {
  object-fit: cover;
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.user-image {
  position: absolute;
  top: 10px;
  left: 10px;
  margin-right: 100px;
  width: 50px;
  height: 50px;
}

.user-image img {
  object-fit: cover;
  width: 50px;
  height: 50px;
  border: 2px solid #6f6f6f;
  border-radius: 30px;
}

.post-username {
  position: absolute;
  display: inline-block;
  top: 25px;
  left: 80px;
}

.post-username h1 {
  color: #12c2e9;
  font-size: 16px;
  text-transform: uppercase;
}

.post-username span {
  color: #12c2e9;
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 5px;
}

.post-title {
  color: #a4a7b1;
  margin-top: 50px;
  /* margin-right: 100px; */
}

.post-title h1 {
  color: #a4a7b1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: center;
  margin-left: 20px;
}

.post-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: start;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 50px;
}

.line {
  border-top: 1px solid rgb(115, 110, 110);
  border-radius: 1px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 100px;
}

/*********************** Media query **************************/

@media (max-width: 1550px) {
  .card {
    display: grid;
    grid-template-columns: 400px 400px 400px;
    height: 400px;
    justify-content: center;
    align-content: center;
    column-gap: 35px;
  }

  .wrap {
    position: relative;
    display: flex;
    box-shadow: #c471ed 0px 0px 10px;
    border-radius: 10px;
    height: 180px;
  }
  .text {
    width: 290px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  }

  .img {
    width: 180px;
    height: 180px;
  }

  .img img {
    object-fit: cover;
    width: 180px;
    height: 180px;
    border-radius: 10px;
  }

  .user-image {
    position: absolute;
    top: 10px;
    left: 10px;
    margin-right: 100px;
    width: 45px;
    height: 45px;
  }

  .user-image img {
    object-fit: cover;
    width: 45px;
    height: 45px;
    border: 2px solid #6f6f6f;
    border-radius: 30px;
  }

  .post-description {
    font-size: 13px;
  }
  .post-username {
    position: absolute;
    display: inline-block;
    top: 25px;
    left: 70px;
  }
  .post-username h1 {
    color: #12c2e9;
    font-size: 15px;
    text-transform: uppercase;
  }

  .post-username span {
    color: #12c2e9;
    font-size: 15px;
    text-transform: uppercase;
    margin-left: 5px;
  }

  .line {
    border-top: 1px solid rgb(115, 110, 110);
    border-radius: 1px;
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 100px;
  }
}

@media (max-width: 1281px) {
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
    margin-bottom: 200px;
    /* height: 400px; */
    gap: 50px;
  }
}
