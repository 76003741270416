.top-users {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 200px;
}

.grid-3-cols {
  display: flex;
  justify-content: center;
  gap: 30px;
  /* width: 800px; */
}

.card {
  position: relative;
  border-radius: 15px;
}

.song-art {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  font-size: 12px;
  margin-top: 5px;
  color: #c471ed;
}

.card:hover {
  /* transform: translateY(-1rem); */
}

.title h3 {
  margin-right: 900px;
  font-size: 36px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 20px;
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-title h1 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 18px;
  color: #a4a7b1;
  line-height: 1.4;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-right: 850px;
  margin-bottom: 100px;
}

.song-img {
}

.song-img:hover {
  filter: brightness(75%);
}

.image img {
  object-fit: cover;
  width: 210px;
  height: 190px;
  border-radius: 5px;
  box-shadow: #3b618765 0px 0px 5px;
}

.artist-name {
  margin-top: 10px;
}

.artist-name h1 {
  color: #a4a7b1;
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
  margin-left: 0px;
}

.artist-name span {
  color: #a4a7b1;
  font-size: 18px;
  text-align: justify;
  padding: 10px;
  margin-left: -6px;
}

.song-link {
  text-decoration: none;
}

.line {
  border-top: 1px solid rgb(115, 110, 110);
  border-radius: 1px;
  width: 1150px;
  /* margin-left: 250px; */
  margin-top: 100px;
}
