section {
  margin-top: 100px;
}

.grid-3-cols {
  display: grid;
  grid-template-columns: 300px 300px 300px 300px;
  grid-template-rows: 300px 100px 50px 50px;
  column-gap: 50px;
  height: 700px;
  justify-content: center;
  align-content: center;
}

.card {
  position: relative;
  /* grid-template-rows: 250px 50px; */
  border: 1px solid #707070;
  border-radius: 15px;
  height: 400px;

  /* margin-bottom: 50px; */
  box-shadow: #c471ed 0px 0px 10px;
}

.song-link {
  text-decoration: none;
}

.song-art h1 {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  font-size: 15px;
  margin-top: 5px;
  color: #c471ed;
}

.card:hover {
  transform: translateY(-1rem);
  box-shadow: #f64f59 0px 0px 15px;
  border-radius: 15px;
}

.title h3 {
  font-size: 36px;
  letter-spacing: 2px;
  text-transform: uppercase;
  /* margin-top: 100px; */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#12c2e9),
    color-stop(#c471ed),
    to(#f64f59)
  );
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-title h1 {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 18px;
  color: #a4a7b1;
  line-height: 1.4;
  font-weight: 400;
  display: flex;
  justify-content: center;
}

.song-img {
  width: 100%;
  /* filter: brightness(75%); */
  margin-top: 7px;
}

.song-img:hover {
  filter: brightness(75%);
}

.image img {
  /* object-fit: cover; */
  width: 290px;
  height: 250px;
  border-radius: 10px;
}

.audios {
  position: absolute;
  top: 100px;
  left: 100px;
  opacity: 1;
}

/* .audios:hover {
  opacity: 1;
} */

.audio {
  border: 5px solid #6f6f6f;
  border-radius: 50px;
  padding: 10px;
  height: 50px;
  width: 50px;
  color: #12c2e9;
}

.song-name {
  color: #12c2e9;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: flex;
  justify-content: flex-start;
}

.artist-name h1 {
  color: #a4a7b1;
  font-size: 14px;
  text-align: justify;
  padding: 10px;
  margin-left: 3px;
}

.artist-name span {
  color: #a4a7b1;
  font-size: 14px;
  text-align: justify;
  padding: 10px;
  margin-left: -6px;
}

.line {
  border-top: 1px solid rgb(115, 110, 110);
  border-radius: 1px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
}

/*********************** Media query **************************/

@media (max-width: 1372px) {
  .grid-3-cols {
    margin-top: 200px;
    display: grid;
    grid-template-columns: 300px 300px;
    grid-template-rows: 300px 100px 50px 50px;
    row-gap: 150px;
    height: 700px;
    justify-content: center;
    align-content: center;
  }
  .line {
    margin-top: 100px;
  }
}

@media (max-width: 660px) {
  .grid-3-cols {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 150px; */
    height: 1700px;
  }
  .line {
    margin-top: 150px;
  }
}
