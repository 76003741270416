/* .section-our-staff {
  height: 1000px;
} */

.our-staffs {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 950px;
  margin-top: 400px;
  width: 1300px;
  gap: 100px;
}

.our-staffs-infos {
  /* width: 500px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 170px;
}

.our-staff-title {
  border-radius: 10px;
  margin-bottom: 100px;
  font-size: 30px;
  padding: 10px;
  font-weight: 300;
  color: #f8f9fa;
  letter-spacing: 3px;
  text-transform: uppercase;
  background-color: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#12c2e9),
    color-stop(#c471ed),
    to(#f64f59)
  );
  background: -webkit-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: -o-linear-gradient(left, #12c2e9, #c471ed, #f64f59);
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
}

.our-staff-sub-title {
  margin-bottom: 50px;
  font-size: 36px;
  font-weight: 400;
  text-align: justify;
  color: #f8f9fa;
  line-height: 1.5;
}

.our-staff-text {
  /* width: 480px; */
  text-align: justify;
  font-weight: 400;
  font-size: 21px;
}

.our-staffs-pics {
  /* position: relative; */
  display: grid;
  grid-template-columns: 300px 300px;
  column-gap: 40px;
  row-gap: 30px;
  margin-right: -100px;
}

/* .our-staffs-pics:hover h1 {
  opacity: 1;
} */

.our-staff-pic {
  display: grid;
  grid-template-columns: 310px;
  grid-template-rows: 260px 100px;
  border: 5px solid;
  border-image: linear-gradient(#12c2e9, #f64f59) 1;
  width: 310px;
  /* padding: 20px; */
}

.our-staff-pic img {
  /* position: relative; */
  /* grid-row: 1 / span 3;
  grid-column: 1 /2; */
  width: 310px;
  height: 360px;
}

.our-staff-pic h1 {
  grid-row: 2 / 3;
  grid-column: 1 /2;
  background-color: #a4a7b1;
  /* opacity: 0; */
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: azure;
  border-radius: 15px 15px 0 0;
}

.our-staff-pic h3 {
  /* opacity: 0; */
  /* display: block; */
  grid-row: 2 / 3;
  grid-column: 1 /2;
  margin-bottom: 20px;
  justify-self: center;
  align-self: flex-end;
  /* margin-left: 50px; */
}

/* .our-staff-pic:nth-child(2)::after {
  content: "Sophia Kleiner: PR Beraterin";
  background-color: #f64f59;
  color: white;
  font-size: 21px;
  border: 3px solid white;
  display: inline-block;
  
  padding: 2px 5px;
} */

.pic-info {
  /* position: absolute;
  top: 0;
  left: 0; */
  border: 10px solid;
  height: 70px;
}

.staff-name {
  padding: 20px;
  color: #a4a7b1;
  font-size: 18px;
  font-weight: 500;
}

.staff-position {
  margin-top: -47px;
  padding-left: 40px;
  color: #a4a7b1;
  font-size: 18px;
  font-weight: 400;
}
