.posts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.post-card {
  /* background-color: #01090f; */
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  /* border: 2px solid; */
  border-radius: 20px;
  box-shadow: #12c2e9bf 0px 0px 5px;
}

.post-user-info {
  position: relative;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 500px;
}
.user-photo {
  margin-top: 10px;
  margin-left: -35px;
}

.user-photo img {
  /* object-fit: cover; */
  width: 40px;
  height: 40px;
  border: 2px solid #c471ed;
  border-radius: 50%;
}

.username {
  margin-top: 5px;
}

.username h2 {
  margin-left: 13px;
  font-size: 21px;
  color: #12c2e9bf;
}

.setting {
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 480px;
  width: 35px;
  height: 35px;
  color: #c471ed;
  /* margin-left: 290px; */
}

.setting-list {
}

.post-image {
  margin-top: 10px;
}

.post-image img {
  object-fit: cover;
  width: 600px;
  height: 300px;
}

.post-text {
  width: 570px;
  margin-top: 30px;
}

.post-text p {
  font-size: 19px;
  text-align: justify;
  font-weight: 300;
}
.post-features {
  display: flex;
  height: 50px;
  width: 570px;
  margin-top: 30px;
  background-color: #01090f;
  border: 1px solid #12c2e9bf;
  border-radius: 10px;
  margin-bottom: 30px;
}

.post-icon {
  color: #a4a7b1;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  margin-left: 10px;
}

.write-post-field {
  margin-top: 8px;
  margin-left: 25px;
}

.write-post-field input {
  width: 380px;
  height: 30px;
  border-radius: 7px;
  color: #f8f8f8;
  border: 1px solid #f8f8f8;
  background: #111215;
  padding-left: 10px;
}

.write-post-field button {
  width: 50px;
  height: 30px;
  border-radius: 7px;
  margin-left: 30px;
  color: #f8f8f8;
  border: 1px solid #f8f8f8;
  background: #111215;
  border-radius: 5px;
  text-decoration: none;
}

button:hover {
  border: 1px solid #12c2e9;
  /* color: #12c2e9; */
}

input::placeholder {
  padding-left: 10px;
  font-size: 17px;
  color: #f8f8f8;
  opacity: 0.3;
}

.post-comments {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #01090f;
  border: 1px solid #12c2e9bf;
  border-radius: 20px;
  width: 570px;
  margin-bottom: 10px;
}

.comments {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  width: 540px;
  /* border-bottom: 2px solid #a4a7b1; */
  border-radius: 15px;
  margin-top: 10px;
  margin-left: 10px;
}

.user-comment-image {
  padding-top: 10px;
  padding-left: 10px;
}

.user-comment-image img {
  width: 30px;
  height: 30px;
  border: 2px solid #c471ed;
  border-radius: 20px;
}

.comment-username {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 12px;
}

.comment-username h1 {
  font-size: 23px;
  font-weight: 500;
  color: #a4a7b1;
}

.comment-username span {
  font-size: 23px;
  font-weight: 500;
  color: #a4a7b1;
  margin-left: 3px;
}

.comment-setting-icon {
  cursor: pointer;
  margin-left: 300px;
  margin-top: -5px;
  width: 35px;
  height: 35px;
  color: #a4a7b1;
}

.setting-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #a4a7b1;
  position: absolute;
  top: -30px;
  right: 40px;
  width: 100px;
  height: 60px;
  border-radius: 10px;
}

.setting-list-link {
  cursor: pointer;
  list-style: none;
  /* color: #a4a7b1; */
}

.setting-list-link:last-child {
  margin-right: 19px;
}

.comment {
  width: 440px;
  margin-left: -5px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 540px;
  border: 2px solid #a4a7b1;
  border-radius: 10px;
}

.comment p {
  font-size: 19px;
  font-weight: 300px;
  text-align: justify;
  width: 520px;
  margin-left: 10px;
}

.link {
  text-decoration: none;
}

.no-post h1 {
  border: 2px solid #12c2e9bf;
  border-radius: 5px;
  padding: 30px;
  font-size: 21px;
  color: #a4a7b1;
  margin-bottom: 50px;
  width: 500px;
}

.you-are-not-login {
  cursor: pointer;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.you-are-not-login h1 {
  color: #c471ed;
  padding-top: 5px;
}

.link {
  text-decoration: none;
}
