.media {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 400px;
  grid-template-rows: 70px 200px 70px;
}

.hanfs {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.hanf {
  font-size: 35px;
  color: azure;
  margin-left: -180px;
}

.social-media {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  justify-self: end;
  align-self: center;
}

.social-media-icon {
  color: azure;
  width: 30px;
  height: 30px;
  border: 1px solid #12c2e9;
  border-radius: 50%;
  padding: 15px;
  margin-left: 30px;
}

.social-media-icon:hover {
  box-shadow: #12c2e9 0px 0px 10px;
  transform: translateY(-5px);
}

.copyright {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  justify-self: start;
  align-self: start;
}

.footer-copyright {
  color: #a4a7b1;
  font-size: 15px;
  font-weight: 400;
  margin-left: 60px;
}

/*********************** Media query **************************/

@media (max-width: 1360px) {
  .media {
    display: flex;
    flex-direction: column;
    margin-right: 700px;
    margin-bottom: 50px;
  }
}
